// Modules
import React, { useRef, useState, useEffect } from 'react'
import Compressor from 'compressorjs'

// Helpers
import { directUpload } from '@helpers/javascript/cable/directUpload'

export const Uploader = ({
  children,
  onUpload,
  multiple = false,
  images   = false,
  capture
}) => {
  const initialState      = { loading: false, files: [], signedIds: [], total: 0 }
  const fileInput         = useRef(null)
  const [state, setState] = useState(initialState)

  const reset      = () => setState(initialState)
  const askForFile = () => fileInput.current.click()
  const onChange   = () => {
    const files = Array.from(fileInput.current.files)
    if (files.length == 0) return
    setState({ loading: true, files, signedIds: [], total: files.length })
  }

  useEffect(() => {
    const file = state.files[0]
    if (file) {
      processFile(file)
    } else if(state.signedIds.length > 0) {
      onUpload(state.signedIds, Array.from(fileInput.current.files))
      reset()
    }
  }, [state.files])

  const processFile = file => {
    images ? compressImage(file) : uploadFile(file)
  }

  const compressImage = image => {
    new Compressor(image, {
      quality:   0.6,
      maxWidth:  1024,
      maxHeight: 1024,
      success:   uploadFile
    })
  }

  const uploadFile = file => {
    directUpload(file)
    .then(onUploaded)
    .catch(reset)
  }

  const onUploaded = signedId => {
    const files = [...state.files]
    files.shift()
    const signedIds = [...state.signedIds, signedId]

    setState({...state, files, signedIds})
  }

  const text   = multiple && state.total > 1 && `${state.signedIds.length} / ${state.total}`
  const accept = images ? "image/*" : "*"

  return (
    <React.Fragment>
      { state.loading && 
        <div className="bg-white d-flex j-c-center a-i-center mb-4 h-41px">
          <div className="dot-elastic"></div>
        </div> 
      }

      <div onClick={askForFile}>
        {children}
      </div>

      <input
        type      = "file"
        accept    = {accept}
        onChange  = {onChange}
        ref       = {fileInput}
        multiple  = {multiple}
        capture   = {capture}
        hidden
      />
    </React.Fragment>
  )
}
