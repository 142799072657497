export const HEADERS = {
  'Content-Type':     'application/json',
  'Accept':           'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Cache-Control':    'no-cache',
  'X-CSRF-Token':     document.querySelector('meta[name="csrf-token"]').getAttribute('content')
}

export const STATUSES = {
  OPEN:        'open',
  SCHEDULED:   'scheduled',
  CLOSED:      'closed',
  IN_PROGRESS: 'in-progress'
}

export const TYPES = {
  REACTIVE:     'Reactive',
  PREVENTIVE:   'Preventive',
  INSTALLATION: 'Installation'
}

export const MODELS = {
  CLIENTS:   'clients',
  SITES:     'sites',
  MATERIALS: 'materials',
  PRODUCTS:  'products'
}

export const MODELS_ICONS = {
  [MODELS.CLIENTS]:   "user",
  [MODELS.SITES]:     "building",
  [MODELS.MATERIALS]: "memory"
}

export const SIGNATURES = {
  MANDATORY: 'Mandatory',
  OPTIONAL:  'Optional',
  DISABLED:  'Disabled'
}

export const PULL_TO_REFRESH_SIZE = 150
export const MANAGER    = 'manager'
export const TECHNICIAN = 'technician'

export const PATHS = {
  clients: {
    index:        '/mobile/clients',
    autocomplete: '/mobile/clients',
    show:         id => `/mobile/clients/${id}`,
    get:          id => `/mobile/clients/${id}.json`,
    fetch:        id => `/mobile/clients/${id}.json?database=true`
  },
  sites: {
    index:        '/mobile/sites',
    autocomplete: '/mobile/sites',
    show:         id => `/mobile/sites/${id}`,
    fetch:        id => `/mobile/sites/${id}.json`,
    history:      id => `/mobile/sites/${id}/history.json`,
    gallery:      id => `/mobile/sites/${id}/gallery.json`,
    newWorkorder: site => `/mobile/interventions/new?client=${site.client.id}&site=${site.id}`
  },
  materials: {
    index:        '/mobile/materials',
    autocomplete: '/mobile/materials',
    scan:         '/mobile/materials/scan.json',
    create:       '/mobile/materials.json',
    show:         id => `/mobile/materials/${id}`,
    fetch:        id => `/mobile/materials/${id}.json`,
    edit:         id => `/mobile/materials/${id}/edit`,
    patch:        id => `/mobile/materials/${id}/edit.json`,
    update:       id => `/mobile/materials/${id}`,
    history:      id => `/mobile/materials/${id}/history`,
    gallery:      id => `/mobile/materials/${id}/gallery`,
    photos:       id => `/mobile/materials/${id}/photos`,
    new:          siteId =>   `/mobile/materials/new?site=${siteId}`,
    build:        siteId =>   `/mobile/materials/new?site=${siteId}.json`,
    newWorkorder: material => `/mobile/interventions/new?client=${material.client.id}&site=${material.site.id}&material=${material.id}`,
    newDemand:    material => `/mobile/demands/new?client=${material.client.id}&site=${material.site.id}&material=${material.id}`,
  },
  meters: {
    index:    '/mobile/meters',
    filter:   '/mobile/meters.json',
    show:     id => `/mobile/meters/${id}`,
    fetch:    id => `/mobile/meters/${id}.json`,
    readings: workorderId => `/mobile/meters/readings.json?workorder=${workorderId}`
  },
  readings: {
    create:  meterId => `/mobile/meters/${meterId}/readings`,
    destroy: id      => `/mobile/readings/${id}`
  },
  invoices: {
    show:        id => `/mobile/invoices/${id}`,
    update:      id => `/mobile/invoices/${id}`,
    confirm:     id => `/mobile/invoices/${id}/confirm`,
    add_payment: id => `/mobile/invoices/${id}/add_payment`
  },
  workorders: {
    index:     '/mobile/interventions',
    calendar:  '/mobile/interventions/calendar.json',
    new:       '/mobile/interventions/new',
    create:    '/mobile/interventions',
    fetch:     id => `/mobile/interventions/${id}.json`,
    fetchData: id => `/mobile/interventions/${id}/fetch_data.json`,
    show:      id => `/mobile/interventions/${id}`,
    version:   id => `/mobile/interventions/${id}/version.json`,
    sync:      id => `/mobile/interventions/${id}/sync.json`,
    update:    id => `/mobile/interventions/${id}`,
    take:      id => `/mobile/interventions/${id}/take.json`,
    invoice:   id => `/mobile/interventions/${id}/invoice`,
    group:     id => `/mobile/interventions/${id}/group`,
    related:   id => `/mobile/interventions/${id}/related`,
    forms: {
      create:   wo =>      `/mobile/interventions/${wo}/custom_forms.json`,
      prefill:  wo =>      `/mobile/interventions/${wo}/custom_forms/prefill.json`,
      destroy: (wo, id) => `/mobile/interventions/${wo}/custom_forms/${id}.json`
    },
    products: {
      create:   wo      => `/mobile/interventions/${wo}/products.json`,
      parts:    wo      => `/mobile/interventions/${wo}/products/spare_parts.json`,
      update:  (wo, id) => `/mobile/interventions/${wo}/products/${id}.json`,
      delete:  (wo, id) => `/mobile/interventions/${wo}/products/${id}.json`,
      fetch:   (wo, id) => `/mobile/interventions/${wo}/products/${id}/fetch_install.json`,
      install: (wo, id) => `/mobile/interventions/${wo}/products/${id}/install.json`,
    },
    photos: {
      create:  wo       => `/mobile/interventions/${wo}/photos.json`,
      destroy: (wo, id) => `/mobile/interventions/${wo}/photos/${id}.json`
    },
    quotes: {
      demand: workorderId => `/mobile/interventions/${workorderId}/quotes.json`
    },
    preventive: {
      fetch:  wo        => `/mobile/interventions/${wo}/preventive.json`,
      update: (wo, id)  => `/mobile/interventions/${wo}/preventive/${id}.json`
    },
    materials: {
      index:    wo      => `/mobile/interventions/${wo}/materials`,
      create:   wo      => `/mobile/interventions/${wo}/materials`,
      destroy: (wo, id) => `/mobile/interventions/${wo}/materials/${id}`
    }
  },
  secondaries: {
    index:     id => `/mobile/interventions/${id}/secondary_workorders.json`,
    startAll:  id => `/mobile/interventions/${id}/secondary_workorders/start_all.json`,
    pauseAll:  id => `/mobile/interventions/${id}/secondary_workorders/pause_all.json`,
    resumeAll: id => `/mobile/interventions/${id}/secondary_workorders/resume_all.json`,
    stopAll:   id => `/mobile/interventions/${id}/secondary_workorders/stop_all.json`,
    closeAll:  id => `/mobile/interventions/${id}/secondary_workorders/close_all.json`,

    start:     id => `/mobile/interventions/${id}/secondary_workorders/start.json`,
    pause:     id => `/mobile/interventions/${id}/secondary_workorders/pause.json`,
    resume:    id => `/mobile/interventions/${id}/secondary_workorders/resume.json`,
    stop:      id => `/mobile/interventions/${id}/secondary_workorders/stop.json`,
    close:     id => `/mobile/interventions/${id}/secondary_workorders/close.json`,

    create:    id => `/mobile/interventions/${id}/secondary_workorders.json`,
    update:    id => `/mobile/secondary_workorders/${id}.json`,
  },
  passages: {
    new:    id => `/mobile/interventions/${id}/passages/new`,
    create: id => `/mobile/interventions/${id}/passages.json`
  },
  products: {
    index:        '/mobile/products',
    new:          '/mobile/products/new',
    create:       '/mobile/products.json',
    scan:         '/mobile/products/scan.json',
    autocomplete: `/mobile/products/autocomplete`,
    show:         id => `/mobile/products/${id}`,
    fetch:        id => `/mobile/products/${id}.json`,
    edit:         id => `/mobile/products/${id}/edit`,
    update:       id => `/mobile/products/${id}.json`,
    stocks:       (productId, userId) => `/products/fetch_stock_for?product_id=${productId}&user_id=${userId}`
  },
  stocks: {
    inventory: '/mobile/stocks/inventory',
    fetch:     id => `/mobile/stocks/${id}.json`,
    modify:    id => `/mobile/stocks/${id}/modify`,
    move:      id => `/mobile/stocks/${id}/move`,
    update:    id => `/mobile/stocks/${id}.json`,
  },
  users: {
    fetch:          '/mobile/users.json',
    account:        '/mobile/users/account.json',
    change_locale:  '/mobile/users/change_locale.json',
    profiles:       '/mobile/users/profiles',
    change_profile: '/mobile/users/change_profile'
  },
  sessions: {
    new:    '/mobile/sessions/new',
    create: '/mobile/sessions.json',
    fetch:  '/mobile/sessions/fetch.json'
  },
  demands: {
    index:     '/mobile/demands',
    create:    '/mobile/demands.json',
    fetch:     '/mobile/demands.json',
    desktop:   '/workorder_demands',
    new:       '/mobile/demands/new',
    newPortal: '/mobile/demands/new_portal',
    show:    id => `/mobile/demands/${id}`,
    convert: id => `/mobile/demands/${id}/convert.json`,
  },
  absences: {
    create: '/mobile/absences.json',
    new:    '/mobile/absences/new'
  },
  files: {
    index:  (type, id) => `/mobile/files.json?record_type=${type}&record_id=${id}`,
    create: (type, id) => `/mobile/files.json?record_type=${type}&record_id=${id}`
  },
  comments: {
    index:  (type, id) => `/mobile/comments.json?record_type=${type}&record_id=${id}`,
    destroy: id =>        `/mobile/comments/${id}.json`
  }
}
