import React, { useState } from "react"
import { deleteRequest, pathname }   from '@helpers/javascript/javascript'
import { Uploader }        from '@shared/uploader'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const PropertyFieldPhotos = ({field, name, images}) => {
  const [imageList, setImageList] = useState(images)

  const removePhoto = index => {
    const photoId = imageList[index].photoId

    if (photoId) {
      const path = `/property_fields/${field.id}/photos/${photoId}`
      const url  = pathname({ path })
      deleteRequest(url).then(() => {
        setImageList(imageList.filter((image) => image.photoId !== photoId))
      })
    } else {
      const copiedImageList = [...imageList]
      copiedImageList.splice(index, 1)
      setImageList(copiedImageList)
    }
  }

  const handleNewImages = (signedIds, files) => {
    files.forEach((file, i) => {
      const reader = new FileReader()
      reader.onload = e => {
        setImageList(prevImageList => ([...prevImageList, { signedId: signedIds[i], url: e.target.result }]))
      }
      reader.readAsDataURL(file)
    })
  }

  return (
    <>
      <div className="image_grid m-0">
        { imageList?.length == 0 && <label>{I18n.t('property_field.no_photos')}</label>}
        { imageList?.length > 0 && imageList.map((image, index) => {
            return ( 
              <div key={index} className="image_grid__block">
                {image.signedId && 
                  <input
                    name     = {name}
                    value    = {image.signedId}
                    onChange = {()=>{}}
                    hidden
                  />
                }
                <div
                  className = "image_grid__actions"
                  onClick   = {() => removePhoto(index)}
                >
                  <FontAwesomeIcon icon="times"/>
                </div>
                <img key={image.url} src={image.url}/>
              </div>
            )
          })
        }
      </div>

      <Uploader images multiple onUpload={handleNewImages} max={4}>
        <div className="material-avatar add-button btn btn-sm btn-primary small mb-0 mt-2 ml-0 w-fit p-relative">
          {
            field.required && imageList.length == 0 && 
            <input style={{height: '24px', border: 'none'}} className="p-absolute" required />
          }
          <FontAwesomeIcon icon="images" /> {I18n.t("extra_fields.add_photo")}
        </div>
      </Uploader>
    </>
  )
}

export default PropertyFieldPhotos
