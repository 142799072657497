import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pathname, postRequest } from '@react/helpers/fetch';
import sanitizeHtml from 'sanitize-html-react';

export const MessageShow = ({ message, setCurrentSelectMessage }) => {
  const [selectedVoteState, setSelectedVoteState] = useState({
    question_id: 0,
    downvote: 0,
    upvote: 0
  });

  const handleBackClick = () => {
    setCurrentSelectMessage(null);
  };

  const handleVote = (newVote) => {
    if (selectedVoteState.upvote || selectedVoteState.downvote) return;

    const increments = {
      up: { upvote: 1, downvote: 0 },
      down: { upvote: 0, downvote: 1 }
    };

    const { upvote, downvote } = increments[newVote];
    setSelectedVoteState({
      question_id: message.id,
      downvote,
      upvote
    });
  };

  useEffect(() => {
    if (selectedVoteState.question_id !== 0) {
      const path = '/chatbot/votes';
      const url = pathname({ path });
      postRequest({ url, body: selectedVoteState });
    }
  }, [selectedVoteState]);

  return (
    <div>
      <FontAwesomeIcon
        icon='arrow-left'
        onClick={handleBackClick}
        className='chat-bot__assistant-back-arrow'
      />
      <div className='mt-2'>
        <div className='chat-bot__wrapper__message-list-container'>
          <div className="chat-bot__question">{message.question}</div>
          <div className='chat-bot__answer chat-bot__answer--expanded'>
            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(message.answer) }} />
            <div className='chat-bot__show-message-actions'>
              <div className='chat-bot__thumbs-vote'>
                <FontAwesomeIcon
                  icon='thumbs-up'
                  className={`chat-bot__up-vote ${selectedVoteState.upvote ? 'chat-bot__up-vote--selected' : ''}`}
                  onClick={!selectedVoteState.upvote && !selectedVoteState.downvote ? () => handleVote('up') : undefined}
                />
                <FontAwesomeIcon
                  icon='thumbs-down'
                  className={`chat-bot__down-vote ${selectedVoteState.downvote ? 'chat-bot__down-vote--selected' : ''}`}
                  onClick={!selectedVoteState.upvote && !selectedVoteState.downvote ? () => handleVote('down') : undefined}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
