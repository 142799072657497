import React, { useState }   from "react"
import Input                 from "@shared/input"
import TextArea              from '@shared/textarea'
import Checkbox              from '@shared/checkbox/checkbox'

const EXTRA_FIELD_ANSWERS_TYPE = {
  BOOLEAN: 'boolean',
  INTEGER: 'integer',
  FLOAT: 'float',
  DATE: 'date',
  MULTI: 'multi',
  CHECK: 'checkbox'
}

export const ExtraFieldAnswer = ({ workorder, answer, onChange }) => {
  const otherField = workorder.extraFieldAnswers.find(
    woField => woField.extraFieldId === answer.dependentFieldId
  )

  const isDependent = (() => {
    if (!otherField) return false;

    return otherField.stringAnswer === answer.dependentFieldOption;
  })();

  const shouldDisplay = !answer.dependentFieldId || isDependent;

  let returnedAnswer

  switch (answer.type) {
  case EXTRA_FIELD_ANSWERS_TYPE.BOOLEAN:
    returnedAnswer =
      <Checkbox
        id           = {`answer_${answer.extraFieldId}`}
        label        = {answer.title}
        checked      = {!!answer.booleanAnswer || false}
        required     = {answer.required}
        onChange     = {e => onChange(e, answer)}
        className    = "pt-3 m-0"
      />
    break

  case EXTRA_FIELD_ANSWERS_TYPE.INTEGER:
    returnedAnswer =
      <Input
        label        = {answer.title}
        defaultValue = {answer.integerAnswer}
        required     = {answer.required}
        onChange     = {value => onChange(value, answer)}
        step         = {1}
        type         = "number"
        inputClass   = "text-center"
        updateOnChange
        plusminus
      />
    break

  case EXTRA_FIELD_ANSWERS_TYPE.FLOAT:
    returnedAnswer =
      <Input
        label        = {answer.title}
        defaultValue = {answer.floatAnswer}
        required     = {answer.required}
        onChange     = {value => onChange(value, answer)}
        step         = {0.01}
        type         = "number"
        inputClass   = "text-center"
        updateOnChange
        plusminus
      />
    break

  case EXTRA_FIELD_ANSWERS_TYPE.DATE:
    returnedAnswer =
      <Input
        label        = {answer.title}
        defaultValue = {answer.dateAnswer}
        required     = {answer.required}
        onChange     = {value => onChange(value, answer)}
        type         = 'date'
        inputClass   = "text-center"
        updateOnChange
      />
    break

  case EXTRA_FIELD_ANSWERS_TYPE.MULTI:
    returnedAnswer =
    (
      <>
        <label required={answer.required}>{ answer.title }</label>
        <select
          className="form-control"
          value    = { answer.stringAnswer || '' }
          onChange = { e => onChange(e.currentTarget.value, answer) }
          required = { answer.required }
        >
          <option value="">{I18n.t("mobile.actions.select")}</option>
          {
            answer.options.map(option =>
              <option key = { option } value = { option }>
                { option }
              </option>
            )
          }
        </select>
      </>
    )
    break
  case EXTRA_FIELD_ANSWERS_TYPE.CHECK:
    const emptyArray = answer.options.map(option => option = false)
    const [multiCheckboxState, setMultiCheckboxState] = useState(answer.multiAnswer || emptyArray)
    const handleMultiCheckbox = (checked, option, answer) => {
      const copiedMultiCheckboxState = [...multiCheckboxState]
      const optionIndex = answer.options.findIndex(el => el === option)
      copiedMultiCheckboxState[optionIndex] = checked
      setMultiCheckboxState(copiedMultiCheckboxState)
      onChange(copiedMultiCheckboxState, answer)
    }
    
    returnedAnswer =
      <>
        <div className="input-group multi-checkbox"><label>{answer.title}</label></div>
        { answer.options.map((option, index) =>
            <Checkbox
              key       = { `answer_${answer.id}_${index}` }
              id        = { `answer_${answer.id}_${index}` }
              label     = { option }
              checked   = { answer.multiAnswer ? answer.multiAnswer[index] : false }
              onChange  = { checked => handleMultiCheckbox(checked, option, answer) }
              className = "mb-0"
            />
        )}
      </>
    break
  default:
    returnedAnswer =
    <TextArea
      title        = { answer.title }
      label        = { answer.title }
      defaultValue = { answer.stringAnswer}
      required     = { answer.required }
      onChange     = { value => onChange(value, answer) }
    />
  }

  return (
    <>
      {
        shouldDisplay && (answer.forManager ?
          <input type="hidden" value={answer.id} />
          :
          <div className="form-group extra-field">
            { returnedAnswer }
          </div>
        )
      }
    </>
  )
}
