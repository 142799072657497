import React           from 'react'
import { useCalendar } from './useCalendar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Checkbox            from '../shared/checkbox/checkbox'

const ResourceList = () => {
  const { state, setState, TOOLS, filter, setFilter } = useCalendar()

  const OPEN_ID            = "open"
  const DISPLAY_INTEGRATED = "integrated"

  const handleResourceChange = (checked, resource) => {
    let unchecked = [...filter.unchecked]
    let resourcesToHandle = TOOLS.resources.filter(res => res.parentId == resource.id || res.id == resource.id)
    resourcesToHandle = resourcesToHandle.map(res => checked ? [res.id, res.parentId] : res.id)
    let result
    if (checked) {
      result = unchecked.filter(item => !resourcesToHandle.flat().includes(item));
    } else {
      result = unchecked.concat(resourcesToHandle.filter(item => !unchecked.includes(item.id)));
    }
    
    setFilter(prevFilter=>({...prevFilter, unchecked: result}))
  }
  
  const openWorkorder = TOOLS.resources.find(resource => resource.id == OPEN_ID)
  const managers = TOOLS.resources.filter(resource => resource.manager == true)

  const userInfo = info => {
    return <span className="mt-1"
              onMouseEnter={e => setState(prevState=>({...prevState, showPopup: {x: e.pageX, y: e.pageY, content: info}}))}
              onMouseLeave={() => setState(prevState=>({...prevState, showPopup: false}))}>
              <FontAwesomeIcon icon='info-circle' />
            </span>
  }

  const Manager = ({ manager }) => {
    const technicians = TOOLS.resources.filter(resource => resource.parentId == manager.id)

    return(
      <>
        <div className="d-flex" key={manager.id}>
          <Checkbox
            checked    = {!filter.unchecked.includes(manager.id)}
            id         = {manager.id}
            onChange   = {checked => handleResourceChange(checked, manager)}
            label      = {manager.title}
            color      = {manager.color}
            className  = "form-group mr-2"
          />
          {manager.information && userInfo(manager.information)}
        </div>

        { technicians.map(technician =>
            <div className="d-flex" key={technician.id}>
              <Checkbox
                className = 'form-group ml-2 mr-2'
                checked   = {!filter.unchecked.includes(technician.id)}
                id        = {technician.id}
                onChange  = {checked => handleResourceChange(checked, technician)}
                label     = {technician.title}
                color     = {technician.color}
              />
              {technician.information && userInfo(technician.information)}
            </div>
        )}
      </>
    )
  }

  return (
    <div className={`resource-list`}>
      <div>
      { TOOLS.displayOpen == DISPLAY_INTEGRATED &&
          <div className="d-flex" key={openWorkorder.id}>
            <Checkbox
              checked    = {!filter.unchecked.includes(openWorkorder.id)}
              id         = {openWorkorder.id}
              onChange   = {checked => handleResourceChange(checked, openWorkorder)}
              label      = {openWorkorder.name}
              color      = {openWorkorder.color}
              className  = "form-group mr-2"
            />
            {openWorkorder.information && userInfo(openWorkorder.information)}
          </div>
        }
        {managers.map(manager => <Manager key={manager.id} manager={manager}/>)}
      </div>
    </div>
  )
}

export default ResourceList
