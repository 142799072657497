const legend = isPurchaseReceipt => {
  if(!isPurchaseReceipt) return null

  return I18n.t("mobile.photos.purchase_receipt_default_legend")
}

// Dispatch
export const addPhotoAction = (
  workorder, 
  base64, 
  isPurchaseReceipt = false, 
  maintenanceTaskId = null,
  fieldId           = null
) => dispatch =>
  dispatch({
    type:    ADD_PHOTO.ACTION,
    payload: { workorder, base64, isPurchaseReceipt, maintenanceTaskId, fieldId }
  })

// Reducer response
export const ADD_PHOTO = {
  ACTION: 'ADD_PHOTO',

  call: ({ state, workorder, base64, isPurchaseReceipt, maintenanceTaskId, fieldId }) => {
    const photo = {
      photo:   base64,
      legend:  legend(isPurchaseReceipt),
      version: new Date().getTime(),
      synced:  false,
      isPurchaseReceipt,
      maintenanceTaskId,
      fieldId
    }

    return {
      ...state,
      items: state.items.map(item => item.id != workorder.id ? item : {
        ...workorder,
        synced: false,
        photos: [...item.photos, photo]
      })
    }
  }
}
